@import "@/styles/mixins";

.cabinetCard {
  @include flex-column;
  gap: var(--size-big);
  padding: var(--size-standard);
  border-radius: var(--size-medium);
  background-color: var(--light-color);

  @media (min-width: #{$breakpoint-desktop}) {
    border-radius: var(--size-standard);
  }
}
