@import "@/styles/mixins";

.container {
  @include flex-column;
  gap: var(--size-standard);

  @media (min-width: #{$breakpoint-desktop}) {
    gap: var(--size-upper-huge);
  }
}

.content {
  display: flex;
  flex-flow: row wrap;
  gap: var(--size-big);

  @media (min-width: #{$breakpoint-desktop}) {
    gap: var(--size-huge);
  }
}

.leftSide {
  flex: 1;
  @include flex-column;
  gap: var(--size-standard);
}

.list {
  @include flex-column;
  gap: var(--size-micro);
  margin-left: var(--size-mini);
}

.rightSide {
  display: flex;
  flex-flow: row wrap;
  gap: var(--size-standard);

  @media (min-width: #{$breakpoint-desktop}) {
    width: var(--column-mini);
  }
}

.tag {
  padding: var(--size-mini) var(--size-standard);
  border-radius: 62px;
  background-color: var(--light-gray);
  @include font-small;
  color: var(--black-nature-color);
}
