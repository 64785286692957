@import '@/styles/mixins.scss';

.calculatorWrapper {
  @include flex-column;
  gap: var(--size-big);
  padding-inline: var(--size-standard);
  user-select: none;
}

.infoBlock {
  display: flex;
  flex-direction: column;
  gap: var(--size-standard);
}

.row {
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;
    gap: var(--size-mini);
    align-items: center;
    color: var(--logo-gray);
  }

  .value {
    @include font-body-bold;

    @media (min-width: #{$breakpoint-desktop}) {
      @include font-title;
      line-height: 20px;
    }
  }
}
