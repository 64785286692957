@import "@/styles/mixins";

.footerLicense {
  @include flex-column;
  gap: var(--size-standard);

  p {
    @include font-caption;
    color: var(--logo-gray);
  }
}
