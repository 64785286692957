@import "@/styles/mixins";

.footer {
  @include flex-column;
  gap: var(--size-big);
  padding: var(--size-big) var(--size-standard);
  background-color: var(--brand-color);
}

.marginTop {
  margin-top: var(--size-upper-huge);
}

.contactsBlock {
  display: flex;
  justify-content: space-between;
  gap: var(--size-big);
}

.logoWrapper {
  @include flex-column;
  gap: var(--size-standard);
}


