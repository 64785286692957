$breakpoint-desktop: 1025px;
$header-height: 80px;
$home-section-vertical-padding: 104px;
$text-button-height: 32px;
$profile-card-height: 60px;

// timeline
$spot-size: 48px;
$spot-border-width: 4px;
$slider-width: 6px;
$thumb-size: 12px;
