@import '@/styles/mixins';

.background {
  position: fixed;
  width: 100vw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: var(--size-standard);
  padding-block: var(--size-big);
  background-color: rgba(0, 0, 0, 0.15);
  z-index: var(--layer-max);

  &.mobileFullSize {
    padding: 0;
  }
}

.modal {
  position: relative;
  @include flex-column;
  gap: var(--size-big);
  width: 100%;
  padding-block: var(--size-huge);
  padding-inline: var(--size-standard);
  border-radius: var(--size-standard);
  background-color: var(--light-color);

  @media(min-width: #{$breakpoint-desktop}) {
    gap: var(--size-huge);
    width: var(--column-medium);
    max-height: 90%;
    padding-inline: var(--size-huge);
    border-radius: var(--size-big);
  }
}

.mobileFullSize .modal {
  @media(max-width: #{$breakpoint-desktop}) {
    height: 100%;
    border-radius: 0;
  }
}

.header {
  display: flex;
  gap: var(--size-standard);
  justify-content: flex-end;
}

.crossButtonWrapper {
  @media(min-width: #{$breakpoint-desktop}) {
    position: absolute;
    top: var(--size-standard);
    right: var(--size-standard);
  }
}

.title {
  flex: 1;
  @include font-h2-mobile;
  text-align: left;

  @media(min-width: #{$breakpoint-desktop}) {
    @include font-h1-desktop;
    text-align: center;
  }
}
