@import "@/styles/mixins";

.navBlock {
  display: flex;
  flex-direction: row;
  background-color: var(--light-color);

  @media (min-width: #{$breakpoint-desktop}) {
    flex-direction: column;
    gap: var(--size-standard);
  }
}

.linkWrapper {
  flex: 1;
  padding: var(--size-micro) var(--size-mini);

  @media (min-width: #{$breakpoint-desktop}) {
    padding: unset;
  }
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-micro);
  padding: var(--size-micro) var(--size-standard);
  border-radius: var(--size-mini);
  text-align: center;
  color: var(--logo-gray);
  transition: all var(--animation-duration-fast);

  &.active {
    background-color: var(--brand-color);
    font-family: var(--font-bold), sans-serif;
    color: var(--black-color);
  }

  @media (min-width: #{$breakpoint-desktop}) {
    flex-direction: row;
    gap: var(--size-medium);
    padding: var(--size-micro) var(--size-mini);
    border-radius: var(--size-micro);
    @include font-menu;

    &:hover:not(.active) {
      background-color: var(--orange-color);
      color: var(--black-color);

      svg * {
        stroke: var(--black-color);
      }
    }
  }
}
