.bankCards {
  display: flex;
  gap: var(--size-mini);
  align-items: center;
}

.cardBase {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background-color: var(--light-color);
}
