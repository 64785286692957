.header {
  position: sticky;
  top: 0;
  z-index: var(--layer-max);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
  padding-inline: var(--size-standard);
  background-color: var(--light-color);
}

.rightSide {
  display: flex;
  align-items: center;
  gap: var(--size-standard);
}

.logoWrapper {
  display: flex;
  align-items: center;
  gap: var(--size-micro);
}
