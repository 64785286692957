@import '@/styles/mixins.scss';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--size-huge);
  width: 100%;
  z-index: var(--layer-min);

  @media (min-width: #{$breakpoint-desktop}) {
    width: var(--column-medium);

    button {
      margin-inline: auto;
    }
  }
}
