@import "@/styles/mixins";

.section {
  flex: 1;
  @include flex-column;
  gap: var(--size-big);
  padding-block: var(--size-standard);
  padding-inline: 0;
  overflow-y: auto;
}

.profileCardWrapper {
  padding-inline: var(--size-standard);
}
