@import './reset';
@import 'variables';
@import './mixins';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  background-color: var(--light-color);
  @include font-body;
  color: var(--black-color);
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

section {
  padding-inline: var(--size-standard);
}

button {
  @media (min-width: #{$breakpoint-desktop}) {
    cursor: pointer;
  }
}
