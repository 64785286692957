@import "@/styles/mixins";

.email {
  display: flex;
  gap: var(--size-standard);
  align-items: center;

  span {
    @include font-menu;
  }
}
