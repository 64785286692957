@import '@/styles/mixins';

.mainButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--size-mini);
  width: 100%;
  padding-block: var(--size-standard);
  padding-inline: var(--size-huge);
  border-radius: var(--size-medium);
  background-color: var(--brand-color);
  font-family: var(--font-bold), sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: var(--black-nature-color);
  overflow: hidden;

  &.secondary {
    background-color: var(--green-color);
    color: var(--light-color)
  }

  &.outlined {
    border: 2px solid var(--orange-color);
    background-color: var(--light-color);
    color: var(--orange-color);
  }

  &.disabled {
    border-color: var(--inactive-color);
    background-color: var(--inactive-color);
    color: var(--off-white);
    cursor: not-allowed;
  }

  @media (min-width: #{$breakpoint-desktop}) {
    width: auto;
    max-width: 100%;
    transition: background-color var(--animation-duration-standard);

    &:hover:not(.outlined, .secondary, .disabled) {
      background-color: var(--orange-color);
    }

    &.secondary:hover {
      background-color: var(--saturated-green);
    }
  }
}

.text {
  @include text-ellipsis;
}

.spinnerWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
