h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-block: 0;
  font-size: inherit;
}

button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background-color: inherit;
  font-size: inherit;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

fieldset {
  border: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
