@import "@/styles/mixins";

.moneyStepsWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--size-mega);

  @media (min-width: #{$breakpoint-desktop}) {
    flex-direction: row;
    gap: var(--size-grand);
  }
}

.item {
  position: relative;
  flex: 1;
  @include flex-column;
  gap: var(--size-mini);

  .decor {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-55%);
    font-family: var(--font-bold), sans-serif;
    font-size: 128px;
    line-height: normal;
    color: var(--yellow-warm);
    z-index: -1;
  }

  .titleWrapper {
    display: flex;
    gap: var(--size-medium);
    align-items: center;
  }

  .itemTitle {
    @include font-title;
  }

  .description {
    color: var(--dark-gray);
  }
}
