@import '@/styles/mixins';

.cardWrapper {
  padding: var(--size-big) var(--size-standard);
  border-radius: var(--size-big) var(--size-big) 0 0;
  background-color: var(--light-color);

  @media (min-width: #{$breakpoint-desktop}) {
    padding: var(--size-huge);
    border-radius: var(--size-big);
  }
}
