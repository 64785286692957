@import "@/styles/mixins";

.section {
  @include flex-column;
  padding-inline: 0;

  .title {
    padding-inline: var(--size-standard)
  }
}
