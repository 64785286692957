@import "@/styles/mixins";

.modal {
  @media(min-width: #{$breakpoint-desktop}) {
    padding-block: var(--size-huge);
    padding-inline: var(--size-huge);
  }
}

.content {
  @include flex-column;
  gap: var(--size-standard);
  overflow-y: scroll;
}
