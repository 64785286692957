@import "constants";

@mixin font-caption {
  font-family: var(--font-medium), sans-serif;
  font-size: 12px;
  line-height: 16px;
}

@mixin font-small {
  font-family: var(--font-medium), sans-serif;
  font-size: 14px;
  line-height: 16px;
}

@mixin font-body {
  font-family: var(--font-medium), sans-serif;
  font-size: 16px;
  line-height: 20px;
}

@mixin font-body-bold {
  font-family: var(--font-bold), sans-serif;
  font-size: 16px;
  line-height: 20px;
}

@mixin font-menu {
  font-family: var(--font-medium), sans-serif;
  font-size: 18px;
  line-height: 24px;
}

@mixin font-title {
  font-family: var(--font-bold), sans-serif;
  font-size: 20px;
  line-height: 24px;
}

@mixin font-button {
  font-family: var(--font-bold), sans-serif;
  font-size: 16px;
  line-height: 16px;
}

@mixin font-h1-desktop {
  font-family: var(--font-bold), sans-serif;
  font-size: 40px;
  line-height: 40px;
}

@mixin font-h1-mobile {
  font-family: var(--font-bold), sans-serif;
  font-size: 28px;
  line-height: 32px;
}

@mixin font-h1 {
  @include font-h1-mobile;

  @media (min-width: #{$breakpoint-desktop}) {
    @include font-h1-desktop;
  }
}

@mixin font-h2-desktop {
  font-family: var(--font-semi-bold), sans-serif;
  font-size: 28px;
  line-height: 32px;
}

@mixin font-h2-mobile {
  font-family: var(--font-semi-bold), sans-serif;
  font-size: 20px;
  line-height: 24px;
}

@mixin font-h2 {
  @include font-h2-mobile;

  @media (min-width: #{$breakpoint-desktop}) {
    @include font-h2-desktop;
  }
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
