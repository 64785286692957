@import '@/styles/mixins';

.contacts {
  display: flex;
  align-items: center;
  gap: var(--size-standard);

  .phoneBlock {
    display: flex;
    flex-direction: column;
  }

  .phoneLink {
    @include font-menu;
  }

  .subTitle {
    @include font-small;
    color: var(--gray-color);
  }
}
