@import '@/styles/mixins';

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--size-mini);
  padding: var(--size-standard);
  border-radius: var(--size-mini);
  background-color: var(--gray-bg);
  transition: all var(--animation-duration-standard);

  &:hover {
    background-color: var(--brand-color);
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  width: 24px;
}

.textWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.title {
  font-family: var(--font-bold), sans-serif;
}

.caption {
  @include font-caption;
  color: var(--special-gray);
}
