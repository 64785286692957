@import "@/styles/mixins";

.wrapper {
  padding-bottom: var(--size-big);
  padding-inline: var(--size-standard);

  @media (min-width: #{$breakpoint-desktop}) {
    width: var(--content-width);
    margin-inline: auto;
  }
}
