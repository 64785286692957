@import '@/styles/mixins.scss';

.decorBlock {
  position: absolute;
  filter: blur(60px);
  z-index: var(--layer-min);

  @media (min-width: #{$breakpoint-desktop}) {
    opacity: 0.6;
    filter: blur(150px);
  }
}
