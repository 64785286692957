@import '@/styles/mixins.scss';

section.section {
  @include flex-column;
  padding-top: 0;
  padding-inline: 0;
}

.module {
  position: relative;
  flex: 1;
  display: flex;
}

.moduleInner {
  position: relative;
  flex: 1;
  @include flex-column;
  gap: var(--size-standard);
  height: calc(100% + var(--size-huge));
  padding-top: var(--size-big);
  padding-inline: var(--size-standard);
}

.leftSpot {
  bottom: 0;
  left: 0;
}

.rightSpot {
  top: 0;
  right: 0;
}

.textWrapper {
  @include flex-column;
  gap: var(--size-mini);
  z-index: var(--layer-middle);
}

h1.title {
  @include font-h1-mobile;
  text-align: center;
  color: var(--black-color)
}

h2.subtitle {
  @include font-h2-mobile;
  text-align: center;
  color: var(--dark-gray);
}

.main {
  position: relative;
  flex: 1;
  display: flex;
  align-items: flex-end;
  z-index: var(--layer-min);

  .bullet {
    max-height: 100%;
    margin-bottom: 60px;
    padding: var(--size-standard);
    border-radius: 20px;
    background: linear-gradient(to right, #fff 30%, rgba(255, 255, 255, 0));
    z-index: var(--layer-min);
  }

  .fakeBulletText {
    max-width: 65%;
    opacity: 0;
  }

  .bulletText {
    position: absolute;
    max-width: 65%;
    max-height: 100%;
    margin-bottom: 60px;
    padding: var(--size-standard);
    z-index: var(--layer-max);
  }

  .title {
    @include font-title;
    color: var(--green-color);
  }

  .subtitle {
    @include font-caption;
    color: rgba(90, 90, 90, 0.87);;
  }

  .image {
    position: absolute;
    top: -27px;
    right: -16px;
    max-width: 60%;
    z-index: var(--layer-middle);
  }
}
