@import "@/styles/mixins";

.contentWrapper {
  flex: 1;
  @include flex-column;
  gap: var(--size-upper-huge);

  @media (min-width: #{$breakpoint-desktop}) {
    gap: $home-section-vertical-padding;
  }
}
