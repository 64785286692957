.pageLoader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(208, 208, 208, 0.5);
  z-index: var(--layer-max);
}
