@import '@/styles/mixins.scss';

.row {
  display: flex;
  justify-content: space-between;
}

.title {
  color: var(--logo-gray)
}

.total {
  @include font-body-bold;

  @media (min-width: #{$breakpoint-desktop}) {
    @include font-title;
    line-height: 20px;
  }
}

.sliderContainer {
  display: flex;
  align-items: center;
  height: 24px;
  margin-top: var(--size-mini);
}

.sliderWrapper {
  position: relative;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: var(--light-gray);

  @media (min-width: #{$breakpoint-desktop}) {
    cursor: pointer;
  }
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 4px;
  background-color: var(--brand-color);
}

.thumb {
  position: absolute;
  top: -12px;
  right: -16px;
  display: flex;

  @media (min-width: #{$breakpoint-desktop}) {
    cursor: pointer;
  }
}

.scaleContainer {
  position: relative;
  height: 32px;
}

.scale {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  color: var(--gray-color);

  &::before {
    position: absolute;
    content: '';
    top: -60%;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 12px;
    background-color: var(--light-gray);
  }
}
