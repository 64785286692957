@import "@/styles/mixins";

.footerAddress {
  @include flex-column;
  gap: var(--size-mini);
  @include font-small;

  @media (min-width: #{$breakpoint-desktop}) {
    max-width: 380px;
  }
}

.textGray {
  color: var(--logo-gray);
}
