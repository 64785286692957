@import "@/styles/mixins";

.footerNav {
  @include flex-column;
  gap: var(--size-standard);
}

.link {
  @include font-menu;
  text-align: left;
  color: var(--dark-gray);
  transition: all var(--animation-duration-standard);

  &:hover {
    font-family: var(--font-bold), sans-serif;
  }

  @media (min-width: #{$breakpoint-desktop}) {
    white-space: nowrap;
    cursor: pointer;
  }
}
