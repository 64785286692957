@import 'constants';

:root {
  // fonts
  --font-medium: 'Raleway-Medium', sans-serif;
  --font-semi-bold: 'Raleway-SemiBold', sans-serif;;
  --font-bold: 'Raleway-Bold', sans-serif;

  // colors
  --light-color: #fff;
  --black-color: #0e0e0e;
  --black-nature-color: #000;
  --black-mute-color: #292d32;
  --brand-color: #ffe601;
  --brand-light-color: #fffbd6;
  --dark-gray: #3d3d3d;
  --logo-gray: #696969;
  --gray-color: #8c8c8c;
  --gray-font-color: #929292;
  --special-gray: #857e95;
  --inactive-color: #e1e1e1;
  --gray-border: #d0d0d0;
  --light-gray: #ededed;
  --gray-bg: #f8f8f8;
  --off-white: #f6f6f6;
  --alert-color: #ff0230;
  --error-color: #d32f2f;
  --mint-color: #cdf9d7;
  --saturated-green: #1c4c27;
  --green-color: #19b500;
  --green-bg: rgba(22, 163, 74, 0.1);
  --orange-intense-color: #ff9900;
  --orange-intense-bg: rgba(255, 153, 0, 0.1);
  --orange-color: #f5c542;
  --yellow-warm: #ffface;
  --light-yellow: #fffef2;
  --kaspi-brand-color: #f14635;
  --halyk-brand-color: #1d9d39;

  // sizes
  --size-grand: 64px;
  --size-mega: 56px;
  --size-pre-mega: 48px;
  --size-upper-huge: 40px;
  --size-huge: 32px;
  --size-big: 24px;
  --size-standard: 16px;
  --size-medium: 12px;
  --size-mini: 8px;
  --size-micro: 4px;

  // layout
  --content-width: 1200px;
  --content-narrow-width: 994px;
  --column-mini: 480px;
  --column-medium: 584px;
  --column-huge: 687px;
  --column-max: 790px;

  --layer-min: 1;
  --layer-semi-middle: 2;
  --layer-middle: 3;
  --layer-semi-max: 9;
  --layer-max: 11;

  // components
  --header-height: #{$header-height};
  --round-button-size: 32px;
  --button-shadow: 0 0 16px 0 rgba(84, 84, 84, 0.15);

  // animation
  --animation-duration-standard: 300ms;
  --animation-duration-fast: 150ms;
}
