@import '@/styles/mixins';

.profileWrapper {
  display: flex;
  gap: var(--size-standard);
  align-items: center;
  height: $profile-card-height;
}

.title {
  @include font-title;
}

.avatarWrapper {
  position: relative;
  display: flex;
  min-width: 48px;
  max-width: 48px;
  height: 100%;
  border-radius: var(--size-micro);
  overflow: hidden;
}

.avatar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
