@import "@/styles/mixins";

.benefitItem {
  flex: 1;
  @include flex-column;
  gap: var(--size-mini);
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: var(--size-mini);
}

.title {
  @include font-title;
}

.description {
  color: var(--dark-gray);
}
