@import "@/styles/mixins";

.container {
  @include flex-column;
  gap: var(--size-mega);

  @media (min-width: #{$breakpoint-desktop}) {
    gap: var(--size-grand);
  }
}
