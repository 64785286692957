@import "@/styles/mixins";

.homePage {
  section {
    padding-top: var(--size-upper-huge);

    @media (min-width: #{$breakpoint-desktop}) {
      padding-top: $home-section-vertical-padding;
    }
  }

  h2 {
    @include font-h1;
    text-align: center;
  }
}
