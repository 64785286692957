.langSwitcher {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 104px;
  height: 36px;
  padding-block: var(--size-mini);
  padding-inline: var(--size-standard);
  border-radius: 30px;
  background-color: var(--light-gray);
}

.label {
  position: relative;
  color: var(--gray-color);
  z-index: var(--layer-semi-middle);
  transition: color var(--animation-duration-standard);

  &.active {
    color: var(--black-color);
  }
}

.thumb {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 56px;
  border-radius: 30px;
  background-color: var(--brand-color);
  transition: left var(--animation-duration-standard);
  z-index: var(--layer-min);

  &.activeLeft {
    left: 0;
  }

  &.activeRight {
    left: 48px;
  }
}
