@import "@/styles/mixins";

.section {
  position: relative;
  padding-inline: 0;

  @media (min-width: #{$breakpoint-desktop}) {
    padding-inline: var(--size-standard);
  }
}

.container {
  position: relative;
  @include flex-column;
  gap: var(--size-big);
  padding: var(--size-upper-huge) var(--size-standard);
  background-color: var(--brand-color);
  z-index: var(--layer-semi-middle);

  @media (min-width: #{$breakpoint-desktop}) {
    gap: var(--size-upper-huge);
    padding: 0;
    background-color: var(--light-color);
  }
}

.main {
  position: relative;

  @media (min-width: #{$breakpoint-desktop}) {
    display: flex;
    gap: var(--size-standard);
    border-radius: var(--size-mega);
    background-color: var(--brand-color);
  }
}

.content {
  flex: 1;
  @include flex-column;
  gap: var(--size-big);

  @media (min-width: #{$breakpoint-desktop}) {
    gap: var(--size-upper-huge);
    padding-block: var(--size-mega);
    padding-left: var(--size-mega);
  }

  .row {
    @include flex-column;
    gap: var(--size-big);

    @media (min-width: #{$breakpoint-desktop}) {
      flex-direction: row;
      gap: var(--size-upper-huge);
    }
  }
}

.imageWrapper {
  position: relative;
  height: 238px;
  z-index: var(--layer-min);

  img {
    position: absolute;
    left: 50%;
    bottom: -100;
    transform: translateX(-50%);
    height: 150%;
  }

  @media (min-width: #{$breakpoint-desktop}) {
    width: 411px;
    height: auto;

    img {
      left: unset;
      right: 0;
      bottom: 0;
      transform: none;
      height: auto;
      width: 100%;
    }
  }
}
