.wrapper {
  position: relative;
}

.flexBlock {
  position: relative;
  z-index: var(--layer-min);
}

.decorBlock {
  position: absolute;
  width: 100%;
  height: 354px;
  padding-inline: var(--size-standard);
  overflow-x: hidden;

  img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    z-index: var(--layer-semi-middle);
  }

  .bgAccent {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 220px;
    background-color: var(--brand-color);
    z-index: var(--layer-min);
  }
}

.childrenBlock {
  position: relative;
  z-index: var(--layer-semi-middle);
}
